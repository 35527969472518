import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

// seo
import Seo from "../components/Seo";

// contants
import constants from "../constants";

// styles
import * as styles from "../components/MetabolismAssessment/Assessment.module.css";

// components
import HeaderWithLogo from "../components/Header/HeaderWithLogo";
import VideoModal from "../components/Home/TwinCanHelp/VideoModal";

const MetabolismAssesmentThankYouPage = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const toggleVideo = url => {
    setVideoUrl(url);
    setShowVideoModal(!showVideoModal);
  };
  return (
    <div>
      <Seo
        title="Diabetes Risk Assessment Quiz | Twin"
        description="The quiz will help you in taking action against diabetes."
      />

      <HeaderWithLogo logoPosition="center" background="white" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.metaAssTy__topSec}>
              <StaticImage
                src="../images/quiz/quiz_ty_icon.png"
                alt="Banner"
                placeholder="blurred"
                quality={90}
              />
              <div className={styles.metaAssTy__topSec__para}>
                <h3>Your Consultation Has Been Booked</h3>
                <p>
                  Our representative will call you on the date & time selected
                </p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={styles.metaAssTy__middleSec}>
              <StaticImage
                src="../images/quiz/quiz_ty_banner.png"
                alt="Banner"
                placeholder="blurred"
                quality={90}
                onClick={() => {
                  toggleVideo(
                    constants.METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO.video1
                  );
                }}
              />
              <p>Don’t forget to watch the video above</p>
            </div>
          </div>
          <div className="col-12">
            <div className={styles.metaAssTy__middleSec__para}>
              <h3>21,000+ Successful Diabetes Remission</h3>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-6 col-md-3 col-lg-3">
            <div className={styles.metaAssTy__testimonial}>
              <StaticImage
                src="../images/quiz/quiz_ty_video1.png"
                alt="Banner"
                placeholder="blurred"
                quality={90}
                onClick={() => {
                  toggleVideo(
                    constants.METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO.video2
                  );
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <div className={styles.metaAssTy__testimonial}>
              <StaticImage
                src="../images/quiz/quiz_ty_video2.png"
                alt="Banner"
                placeholder="blurred"
                quality={90}
                onClick={() => {
                  toggleVideo(
                    constants.METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO.video3
                  );
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <div className={styles.metaAssTy__testimonial}>
              <StaticImage
                src="../images/quiz/quiz_ty_video3.png"
                alt="Banner"
                placeholder="blurred"
                quality={90}
                onClick={() => {
                  toggleVideo(
                    constants.METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO.video4
                  );
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <div className={styles.metaAssTy__testimonial}>
              <StaticImage
                src="../images/quiz/quiz_ty_video4.png"
                alt="Banner"
                placeholder="blurred"
                quality={90}
                onClick={() => {
                  toggleVideo(
                    constants.METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO.video5
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={videoUrl}
      />
    </div>
  );
};

export default MetabolismAssesmentThankYouPage;
